<template>
  <Layout>
    <div v-if="!this.isBusy" class="wrapper">
      <div id="new-comment">
        <b-form @submit="onSubmit">
          <div>
            <div class="created-at">
              <h6>Add a new Comment:</h6>
            </div>
            <div class="comments-content">
              <b-row class="mb-4">
                <b-col sm="2">
                  <b-form-group
                    id="input-group-1"
                    label="Message:"
                    label-for="message-textarea"
                  ></b-form-group>
                </b-col>
                <b-col sm="10">
                  <b-form-textarea
                    v-model="form.message"
                    id="message-textarea"
                    placeholder="Enter message"
                    rows="3"
                    max-rows="8"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row> </b-row>
              <b-row>
                <template>
                  <b-col sm="2">
                    <b-form-group
                      id="input-group-2"
                      label="Attachments:"
                    ></b-form-group>
                  </b-col>
                  <b-col sm="10">
                    <template>
                      <div>
                        <b-form-tags
                          no-outer-focus
                          class="mb-2"
                          v-model="form.tags"
                        >
                          <template
                            v-slot="{
                              tags,
                              inputAttrs,
                              inputHandlers,
                              tagVariant,
                              addTag,
                              removeTag,
                            }"
                          >
                            <b-input-group class="mb-2">
                              <b-form-input
                                v-bind="inputAttrs"
                                v-on="inputHandlers"
                                placeholder="New link - Press enter to add"
                                class="form-control"
                              ></b-form-input>
                              <b-input-group-append>
                                <b-button @click="addTag()" variant="primary"
                                  >Add</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <div
                              class="d-inline-block"
                              style="font-size: 1.5rem"
                            >
                              <b-form-tag
                                v-for="tag in tags"
                                @remove="removeTag(tag)"
                                :key="tag"
                                :title="tag"
                                :variant="tagVariant"
                                class="mr-1"
                                >{{ tag }}</b-form-tag
                              >
                            </div>
                          </template>
                        </b-form-tags>
                      </div>
                    </template>
                  </b-col>
                </template>
              </b-row>
              <div>
                <b-button type="submit" variant="primary">
                  Save Comment
                  <span
                    :class="{
                      'spinner-border spinner-border-sm': this.saveComment,
                    }"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </b-button>
              </div>
            </div>
          </div>
        </b-form>
      </div>
      <div class="comments-section">
        <div class="created-at">
          <span v-if="this.ticket.status == 0" class="open">Open</span>
          <span v-else class="closed">Closed</span>
          &nbsp; Created
          {{
            calculateDuration(
              Math.floor(
                moment
                  .duration(moment().diff(moment(this.ticket.createdAt)))
                  .asDays()
              )
            )
          }}
          by &nbsp;
          <span v-if="this.ticket.user">
            <img
              v-if="this.ticket.user.profile_pic"
              v-bind:src="getImage(this.ticket.user.profile_pic)"
              class="avatar-image"
            />

            <span v-else></span>
            <strong>{{ this.ticket.user.email }}</strong>
          </span>
        </div>
        <div class="comments-content">
          <h2>{{ this.ticket && this.ticket.message }}</h2>
          <div v-if="checkCommentsLength(this.ticket)">
            <div
              v-for="(comment, index) in filteredComments.slice().reverse()"
              :key="index"
            >
              <b-card-group deck>
                <b-card
                  class="mb-4"
                  :title="comment.message"
                  header-tag="header"
                  footer-tag="footer"
                >
                  <template #header>
                    <p v-if="comment.admin_user" class="mb-0">
                      {{ comment.admin_user.email }}
                    </p>
                    <p v-else class="mb-0">{{ comment.user.email }}</p>
                  </template>
                  <!-- <b-card-text>{{comment.message }}</b-card-text> -->
                  <ul
                    
                  >
                    <li v-for="(attachment, index) in comment.attachment"
                    :key="index">
                      <a
                        :href="attachment"
                        target="_blank"
                        class="attachment"
                        >{{ attachment }}</a
                      >
                    </li>
                  </ul>
                  <template #footer>
                    <em>{{ moment(comment.createdAt).format("lll") }}</em>
                  </template>
                </b-card>
              </b-card-group>
            </div>
            <!-- <ul v-for="comment in this.ticket.comments" :key="comment._id">
            <li>{{ comment.message }}</li>
            <div v-if="attachmentExists(comment)">
              <ul
                v-for="attachment in comment.attachment"
                :key="attachment._id"
              >
                <li>{{ attachment }}</li>
              </ul>
            </div>
          </ul> -->
          </div>
          <div v-else>
            <div class="no-comments">No comments yet</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading-indicator">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </Layout>
</template>

<script>
import { tickets } from "@/config/api/tickets";
import Layout from "@/views/layouts/main";
import moment from "moment";
export default {
  components: {
    Layout,
  },
  mounted() {
    this.getTicketById(this.$route.params.ticket_id);
  },
  data() {
    return {
      ticket: null,
      isBusy: true,
      form: {
        message: "",
        tags: [],
      },
      errors: [],
      saveComment: false,
    };
  },
  computed: {
    filteredComments: function () {
      console.log(this.ticket.comments);
      this.ticket.comments
        .slice()
        .sort((a, b) => moment(b.createdAt).isBefore(moment(a.createdAt)));
      return this.ticket.comments;
    },
  },
  methods: {
    getImage(path) {
      return path;
    },
    calculateDuration(days) {
      // days = 30;
      var durationText = "";
      if (days == 0) {
        return " today";
      } else if (days > 12 && days < 18) {
        return "about two weeks ago";
      } else if (days > 17 && days < 27) {
        return "about three weeks ago";
      } else if (days < 30) {
        durationText = days == 1 ? " day ago" : " days ago";
        return days + durationText;
      } else if (days < 360) {
        var months = Math.floor(days / 30);
        durationText = months == 1 ? " month ago" : " months ago";
        return months + durationText;
      } else {
        var years = Math.floor(days / 365);
        durationText = years <= 1 ? "1 year ago" : years + " years ago";
        return durationText;
      }
    },
    getTicketById(ticket_id) {
      this.isBusy = true;

      const api = tickets.getTicketById;
      api.id = ticket_id;
      this.generateAPI(api)
        .then((res) => {
          this.ticket = res.data.ticket;
          this.loading = false;
          // this.totalRows = res.data.count;
          // console.log(res.data);
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.$emit("dataLoaded");
          this.isBusy = false;
        });
    },
    checkCommentsLength(data) {
      if (data == null) {
        return false;
      }
      if ("comments" in data) {
        // console.log("Comments not null");
        if (data.comments.length > 0) {
          return true;
        }
      } else {
        // console.log("Comments null");
        return false;
      }
    },
    attachmentExists(data) {
      if (data == null) {
        return false;
      }
      if ("attachment" in data) {
        // console.log("attachment not null");
        if (data.attachment.length > 0) {
          return true;
        }
      } else {
        // console.log("attachment null");
        return false;
      }
    },
    onSubmit(event) {
      event.preventDefault();
      this.errors = [];
      if (this.form.message.trim() == "") {
        this.errors.push("Enter a message");
        document
          .getElementById("message-textarea")
          .classList.add("error-border");
      } else {
        document
          .getElementById("message-textarea")
          .classList.remove("error-border");
      }

      if (this.errors.length == 0) {
        this.saveComment = true;

        const api = tickets.addComment;
        api.id = this.ticket._id;
        api.data = { message: this.form.message, attachment: this.form.tags };
        // console.log(api);
        this.generateAPI(api)
          .then(() => {
            // this.ticketsData = res.data.tickets;
            // console.log(res);
            this.$bvToast.toast("Comment added successfully!", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            console.error(err.response.data);
            this.$bvToast.toast(err.response.data, {
              title: "Error",
              variant: "error",
              solid: true,
            });
          })
          .finally(() => {
            this.saveComment = false;
            this.$emit("dataLoaded");
            location.reload();
          });
      }
      // alert(JSON.stringify(this.errors));
    },
  },
};
</script>
<style scoped>
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.avatar-image {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.error-border {
  border: 1px solid red;
}
.open {
  background-color: green;
  color: white;
  padding: 5px;
  border-radius: 5px;
}
.closed {
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
}
.no-comments {
  /* max-width: 1200px; */
  justify-content: center;
  text-align: center;
  border: 1px dashed lightgrey;
  padding: 2rem;
  margin-bottom: 2rem;
}
.wrapper {
  background-color: white;
  /* padding-top: 2rem; */
  margin: 0 auto;
  max-width: 90%;
  margin-bottom: 10px;
}
.created-at {
  align-items: center;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  padding: 1rem;
  /* margin-top: 1rem; */
  background-color: lightgrey;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  /* background-image: linear-gradient(to right, #fafafa, #f2f2f2); */
}
.comments-content {
  padding: 3rem 2rem 3rem 2rem;
}
.attachment {
  color: blue;
  text-decoration: underline !important;
}
</style>
