var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[(!this.isBusy)?_c('div',{staticClass:"wrapper"},[_c('div',{attrs:{"id":"new-comment"}},[_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('div',[_c('div',{staticClass:"created-at"},[_c('h6',[_vm._v("Add a new Comment:")])]),_c('div',{staticClass:"comments-content"},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Message:","label-for":"message-textarea"}})],1),_c('b-col',{attrs:{"sm":"10"}},[_c('b-form-textarea',{attrs:{"id":"message-textarea","placeholder":"Enter message","rows":"3","max-rows":"8"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)],1),_c('b-row'),_c('b-row',[[_c('b-col',{attrs:{"sm":"2"}},[_c('b-form-group',{attrs:{"id":"input-group-2","label":"Attachments:"}})],1),_c('b-col',{attrs:{"sm":"10"}},[[_c('div',[_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var tags = ref.tags;
                          var inputAttrs = ref.inputAttrs;
                          var inputHandlers = ref.inputHandlers;
                          var tagVariant = ref.tagVariant;
                          var addTag = ref.addTag;
                          var removeTag = ref.removeTag;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"New link - Press enter to add"}},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v("Add")])],1)],1),_c('div',{staticClass:"d-inline-block",staticStyle:{"font-size":"1.5rem"}},_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-1",attrs:{"title":tag,"variant":tagVariant},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])}),1)]}}],null,false,3639331811),model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}})],1)]],2)]],2),_c('div',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Save Comment "),_c('span',{class:{
                    'spinner-border spinner-border-sm': this.saveComment,
                  },attrs:{"role":"status","aria-hidden":"true"}})])],1)],1)])])],1),_c('div',{staticClass:"comments-section"},[_c('div',{staticClass:"created-at"},[(this.ticket.status == 0)?_c('span',{staticClass:"open"},[_vm._v("Open")]):_c('span',{staticClass:"closed"},[_vm._v("Closed")]),_vm._v("   Created "+_vm._s(_vm.calculateDuration( Math.floor( _vm.moment .duration(_vm.moment().diff(_vm.moment(this.ticket.createdAt))) .asDays() ) ))+" by   "),(this.ticket.user)?_c('span',[(this.ticket.user.profile_pic)?_c('img',{staticClass:"avatar-image",attrs:{"src":_vm.getImage(this.ticket.user.profile_pic)}}):_c('span'),_c('strong',[_vm._v(_vm._s(this.ticket.user.email))])]):_vm._e()]),_c('div',{staticClass:"comments-content"},[_c('h2',[_vm._v(_vm._s(this.ticket && this.ticket.message))]),(_vm.checkCommentsLength(this.ticket))?_c('div',_vm._l((_vm.filteredComments.slice().reverse()),function(comment,index){return _c('div',{key:index},[_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{staticClass:"mb-4",attrs:{"title":comment.message,"header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(comment.admin_user)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(comment.admin_user.email)+" ")]):_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(comment.user.email))])]},proxy:true},{key:"footer",fn:function(){return [_c('em',[_vm._v(_vm._s(_vm.moment(comment.createdAt).format("lll")))])]},proxy:true}],null,true)},[_c('ul',_vm._l((comment.attachment),function(attachment,index){return _c('li',{key:index},[_c('a',{staticClass:"attachment",attrs:{"href":attachment,"target":"_blank"}},[_vm._v(_vm._s(attachment))])])}),0)])],1)],1)}),0):_c('div',[_c('div',{staticClass:"no-comments"},[_vm._v("No comments yet")])])])])]):_c('div',{staticClass:"loading-indicator"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }